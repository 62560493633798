.ourTeam,
.abtSection,
.aboutSection_1{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.abtSection{
    background-image: url('../../Assets/Images/noBg.png');
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform;
}

.abtSection:hover {
    background-position: top center;
    transition: all 1s;
}