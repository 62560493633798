.servicesContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.servicesCon {
    background-image: url('../../Assets/Images/noBg.png');
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform;
}

.servicesCon:hover {
    background-position: top center;
    transition: all 1s;
}